@use "sass:math";

// Colors
$color-primary: #15b8d6;
$color-primary-light: #44badc;
$color-secondary: #0060a7;

$color-variant-1: #fab600;
$color-variant-1-dark: #ed6c4d;

$color-variant-2-light: #db70a5;
$color-variant-2: #e50071;
$color-variant-2-dark: #86134c;
$color-variant-2-darkest: #4f1733;

$color-variant-3-light: #c1d38d;
$color-variant-3: #98c21d;
$color-variant-3-dark: #61791b;
$color-variant-3-darkest: #2b3314;

$color-variant-4-light: #80b0df;
$color-variant-4: #197ee1;
$color-variant-4-dark: #004a93;
$color-variant-4-darkest: #0a3157;

$color-variant-5-light: #98c8c5;
$color-variant-5: #00aba0;
$color-variant-5-dark: #126862;
$color-variant-5-darkest: #0b3d39;

$color-variant-6-light: #eef1f2;
$color-variant-6: #9fafb7;
$color-variant-6-dark: #52646d;

$color-light: #ffffff;
$color-dark: $color-variant-6-dark;

// Navigation
$navigation-panel-width: 300px;
$menu-top-height: 100px;
$footer-height: 110px;
$footer-mobile-height: 350px;
$progress-bar-height: 10px;

// Grid
$grid-columns: 12;

// TODO Reduce gutter-width for small screens
$grid-gutter-width: 30px;

$grid-breakpoints: (
  xs: 340px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1460px,
  xxxl: 1740px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px - $navigation-panel-width,
  xl: 1140px - $navigation-panel-width,
  xxl: 1390px - $navigation-panel-width,
  xxxl: 1670px - $navigation-panel-width,
);
$container-padding: 15px;

// TODO Avoid using these
$columns-widths: (
  sm: math.div(map-get($container-max-widths, sm) - 11 * $grid-gutter-width, 12),
  md: math.div(map-get($container-max-widths, md) - 11 * $grid-gutter-width, 12),
  lg: math.div(map-get($container-max-widths, lg) - 11 * $grid-gutter-width, 12),
  xl: math.div(map-get($container-max-widths, xl) - 11 * $grid-gutter-width, 12),
  xxl:
    math.div(map-get($container-max-widths, xxl) - 11 * $grid-gutter-width, 12),
  xxxl:
    math.div(map-get($container-max-widths, xxxl) - 11 * $grid-gutter-width, 12),
);

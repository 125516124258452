@import "./variables.scss";
@import "../global/mixins.scss";

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  scroll-padding-top: $menu-top-height + $progress-bar-height;
}

// @page {
//   margin: 100px 0;
// }
